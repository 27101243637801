import React from 'react';
import { Close, DeleteButton, Modal, ModalBody, ModalContent, ModalHeader } from './style';
import { useFilesList } from '../../customHooks/useFilesList';
import { useEffect } from 'react';
import { FormStyled } from '../../../../../css/styles/form';

export const ConfirmDeleteFileModal = ({
  isOpen,
  file,
  setIsOpenConfirmDeletionModal,
  setLoadList,
  loadList,
  moduleUuid,
  deleteFile
}) => {

  const handleDeleteFile = () => {
    // setLoadList(true);
    deleteFile(file.uuid);
    setIsOpenConfirmDeletionModal(false);
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalContent>
        <ModalHeader>
          <Close onClick={() => setIsOpenConfirmDeletionModal(false)}>
            &times;
          </Close>
        </ModalHeader>
        <ModalBody>
          <p>Você tem certeza que deseja excluir o arquivo "{file?.name}"?</p>
          <div>
            {file && (
              <DeleteButton
                className="delete"
                type="button"
                onClick={handleDeleteFile}
              >
                EXCLUIR
              </DeleteButton>
            )}
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

