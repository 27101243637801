import React, { useEffect, useState } from 'react';
import API from '../../../../services/api';
import Axios from 'axios';
import { toast } from 'react-toastify';

export const useFilesList = moduleUuid => {
  const [files, setFiles] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [loadList, setLoadList] = React.useState(false);
  const [error, setError] = React.useState('');
  const [AWSUrlObject, setAWSUrlObject] = React.useState(null);
  const [fileToUpload, setFileToUpload] = React.useState(null);

  const fetchFilesById = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await API.get(`/equipmentfiles/list/${moduleUuid}`);
      setFiles(response.data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const attachNewFile = async file => {
    setLoading(true);
    setFileToUpload(file);
    try {
      const getUploadAwsUrl = async () => {
        const response = await API.post(`/files/upload`, { name: file.name });
        return response.data;
      };

      const uploadFileToAws = async () => {
        const awsUrl = await getUploadAwsUrl();
        setAWSUrlObject(awsUrl);

        try {
          const response = await Axios.put(awsUrl.url, file, {
            headers: {
              'Content-Type': 'application/pdf',
            },
          });
          if (response.status === 403) {
            setError('Upload forbidden: status 403');
            return null;
          } else {
            return awsUrl;
          }
        } catch (error) {
          setError(error.message);
          throw error;
        }
      };

      const awsUrl = await uploadFileToAws();
      if (!awsUrl) return;
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
      setFileToUpload(null);
      setAWSUrlObject(null);
    }
  };

  const deleteFile = async fileUuid => {
    setLoading(true);
    setError('');

    try {
      await API.delete(`/files/${fileUuid}`);
      setFiles(prevFiles => prevFiles.filter(file => file.uuid !== fileUuid));
      toast.success('Arquivo deletado com sucesso!');
      fetchFilesById();
    } catch (error) {
      setError(error.message);
      toast.error('Erro ao deletar arquivo');
    }
  };

  const downloadFileFromAWS = async url => {
    try {
      const response = await API.post('/files/download', { name: url });
      const link = document.createElement('a');
      link.href = response.data?.url;
      link.target = '_blank';
      link.rel = 'noopener noreferrer';
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      setError(error.message);
    }
  };

  const postFile = async () => {
    try {
      await API.post(`/equipmentfiles`, {
        name: fileToUpload.name,
        equipmentUuid: moduleUuid,
        url: AWSUrlObject.fileName,
      });
      toast.success('Arquivo postado com sucesso!');
      fetchFilesById();
    } catch (error) {
      console.error('Error posting file:', error);
      toast.error('Erro ao postar arquivo');
    }
  };

  const handleLoadList = () => {
    setLoadList(true);
    setLoadList(false);
  };

  useEffect(() => {
    if (AWSUrlObject && fileToUpload) {
      postFile();
    }
  }, [AWSUrlObject, fileToUpload, moduleUuid]);

  return {
    fetchFilesById,
    files,
    loading,
    setLoading,
    loadList,
    setLoadList,
    error,
    attachNewFile,
    downloadFileFromAWS,
    deleteFile,
    handleLoadList,
  };
};

