import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Select from 'react-select';

// Styles
import { FormStyled, Row, Column } from '../../css/styles/form';
import { SelectStyled } from '../../css/styles/select';
import FilesModal from '../components/FilesModal';

const handleValidate = ({ code }) => {
  const errors = {};
  if (!code) errors.code = 'OBRIGATÓRIO';
  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  if (!props.equipment) {
    await props.onCreation({ ...values });
  } else {
    await props.onUpdate({ ...values });
  }
  setSubmitting(false);
  resetForm();
};


const EquipmentForm = props => {
  const { onDelete, onNew, onEdit, equipment, equipments } = props;
  const statusOptions = [
    {
      value: 'ATIVO',
      label: 'ATIVO',
    },
    {
      value: 'MANUTENÇÃO',
      label: 'MANUTENÇÃO',
    },
    {
      value: 'PARADO',
      label: 'PARADO',
    },
  ];
  const [isOpen, setIsOpen] = React.useState(false);
  
  const handleOpenFilesModal = () => {
    setIsOpen(true);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        code: equipment ? equipment.code : '',
        year: equipment ? equipment.year : '',
        prefix: equipment ? equipment.prefix : '',
        number: equipment ? equipment.number : '',
        plate: equipment ? equipment.plate : '',
        model: equipment ? equipment.model : '',
        status: equipment ? equipment.status : '',
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleReset,
        errors,
        touched,
        setFieldValue,
        isSubmitting,
        dirty,
      }) => (
        <FormStyled>
          <h1>NOVO VEÍCULO</h1>

          <ul>
            <Row>
              <Column>
                <strong>CÓDIGO</strong>
                <input
                  id="code"
                  name="code"
                  type="text"
                  value={values.code}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  key={values.equipment && values.equipment.uuid}
                />

                {errors.code && touched.code && <span>{errors.code}</span>}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>PLACA</strong>
                <input
                  id="plate"
                  name="plate"
                  type="text"
                  value={values.plate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  key={values.equipment && values.equipment.uuid}
                />

                {errors.plate && touched.plate && <span>{errors.plate}</span>}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>MODELO</strong>
                <input
                  id="model"
                  name="model"
                  type="text"
                  value={values.model}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  key={values.equipment && values.equipment.uuid}
                />

                {errors.model && touched.model && <span>{errors.model}</span>}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>ANO</strong>
                <input
                  id="year"
                  name="year"
                  type="text"
                  value={values.year}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  key={values.equipment && values.equipment.uuid}
                />

                {errors.year && touched.year && <span>{errors.year}</span>}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>PREFIXO</strong>
                <input
                  id="prefix"
                  name="prefix"
                  type="text"
                  value={values.prefix}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  key={values.equipment && values.equipment.uuid}
                />

                {errors.prefix && touched.prefix && (
                  <span>{errors.prefix}</span>
                )}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>NÚMERO</strong>
                <input
                  id="number"
                  name="number"
                  type="number"
                  value={values.number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  key={values.equipment && values.equipment.uuid}
                />

                {errors.number && touched.number && (
                  <span>{errors.number}</span>
                )}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>STATUS</strong>
                <select
                  id="status"
                  name="status"
                  value={values.status}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  key={values.equipment && values.equipment.uuid}
                >
                  <option value="" />
                  {statusOptions.map(status => (
                    <option key={status.value} value={status.value}>
                      {status.label}
                    </option>
                  ))}
                </select>

                {errors.status && touched.status && (
                  <span>{errors.status}</span>
                )}
              </Column>
            </Row>
            <Row>
              <Column>
                <strong>STATUS</strong>
                <select
                  id="status"
                  name="status"
                  value={values.status}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  key={values.equipment && values.equipment.uuid}
                >
                  <option value="" />
                  {statusOptions.map(status => (
                    <option key={status.value} value={status.value}>
                      {status.label}
                    </option>
                  ))}
                </select>

                {errors.status && touched.status && (
                  <span>{errors.status}</span>
                )}
              </Column>
            </Row>

            <Row>
              <div className="buttons-container">
                <button type="submit" disabled={isSubmitting}>
                  SALVAR
                </button>

                <button
                  type="button"
                  onClick={handleReset}
                  disabled={!dirty || isSubmitting}
                >
                  RESET
                </button>

                {equipment && (   
                  <>
                    <button type="button" onClick={onNew}>
                      LIMPAR
                    </button>

                    <button className="delete" type="button" onClick={onDelete}>
                      EXCLUIR
                    </button>

                    <button className="files" type="button" onClick={handleOpenFilesModal}>
                      ARQUIVOS
                    </button>
                  </>
                )}
              </div>
            </Row>
          </ul>

          <hr />

          <ul>
            <Row>
              <Column>
                <strong>VEÍCULO</strong>
                <Select
                  styles={SelectStyled}
                  id="equipment"
                  value={values.equipment}
                  placeholder="Selecione um veículo"
                  onChange={selectedOption => {
                    setFieldValue('equipment', selectedOption);
                    onEdit(selectedOption);
                  }}
                  isSearchable
                  options={equipments}
                  getOptionLabel={item => item.code}
                  getOptionValue={item => item.uuid}
                  name="equipment"
                  isLoading={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  loadingMessage={() => 'Buscando veículos'}
                  noOptionsMessage={() => 'Não há veículos cadastrados'}
                />
              </Column>
            </Row>
          </ul>
          {equipment && (
        <FilesModal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          onConfirm={() => setIsOpen(false)}
          moduleUuid={equipment?.uuid || ''}
        />
      )}
        </FormStyled>
      )}
    {/* <FilesModal isOpen={isOpen} moduleUuid={equipment?.uuid} /> */}
    </Formik>
  );
};

EquipmentForm.propTypes = {
  equipments: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      uuid: PropTypes.string,
    })
  ),
  equipment: PropTypes.shape({
    code: PropTypes.string,
    year: PropTypes.string,
    plate: PropTypes.string,
    model: PropTypes.string,
    prefix: PropTypes.string,
    number: PropTypes.number,
    status: PropTypes.oneOf(['ATIVO', 'MANUTENÇÃO', 'PARADO', null]),
  }),
  onDelete: PropTypes.func.isRequired,
  onNew: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

EquipmentForm.defaultProps = {
  equipments: [],
  equipment: null,
};

export default EquipmentForm;
