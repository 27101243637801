import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useFilesList } from './customHooks/useFilesList';
import {
  Close,
  List,
  ListHeader,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ScrollMenu,
  ModalOverlay, // Adicionado para capturar clique fora do modal
} from './style';
import { FiEye } from 'react-icons/fi';
import { IoMdTrash } from 'react-icons/io';
import { ConfirmDeleteFileModal } from './utils/ConfirmDeleteFile';
import FileButton from './utils/FileButton';
import moment from 'moment';

const FilesModal = ({ isOpen, onClose, moduleUuid }) => {
  const [isOpenConfirmDeletionModal, setIsOpenConfirmDeletionModal] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const fileInputRef = useRef(null);
  const modalRef = useRef(null); // Referência para capturar cliques fora do modal

  const {
    fetchFilesById,
    files,
    loading,
    loadList,
    attachNewFile,
    downloadFileFromAWS,
    deleteFile,
    setLoadList
  } = useFilesList(moduleUuid);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'; // Desativa o scroll
    } else {
      document.body.style.overflow = ''; // Restaura o scroll
    }
    return () => {
      document.body.style.overflow = ''; // Garante que o scroll é restaurado ao desmontar
    };
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      fetchFilesById(moduleUuid);
    }
  }, [isOpen]);

  const handleFileUpload = event => {
    const file = event.target.files[0];
    if (file) {
      attachNewFile(file);
      fileInputRef.current.value = '';
    }
  };

  const handleOutsideClick = event => {
    if (modalRef.current && !modalRef.current.contains(event.target) && !isOpenConfirmDeletionModal) {
      onClose(); // Fecha o modal ao clicar fora
    }
  };

  return isOpen ? (
    <ModalOverlay onClick={handleOutsideClick}>
      <Modal isOpen={isOpen}>
        <ModalContent ref={modalRef}>
          <ModalHeader>
            <Close onClick={onClose}>&times;</Close>
            <FileButton fileInputRef={fileInputRef} attachNewFile={handleFileUpload} />
          </ModalHeader>
          <ModalBody>
            <ScrollMenu>
              <ListHeader>
                <p>Nome</p>
                <p>Data de Criação</p>
                <p></p>
              </ListHeader>
              {!loading && files?.length > 0 ? (
                <List>
                  {files.map(equipmentFile => (
                    <ListItem key={equipmentFile.uuid}>
                      <p>{equipmentFile.file.name}</p>
                      <p>{moment(equipmentFile.createdAt).format('DD/MM/YYYY - HH:mm')}</p>
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
                        <div
                          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}
                          onClick={() => downloadFileFromAWS(equipmentFile?.file?.url)}
                        >
                          <FiEye style={{ width: '1.2rem', height: '1.2rem' }} />
                          <span style={{ fontSize: '0.8rem' }}>Visualizar</span>
                        </div>
                        <div
                          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}
                          onClick={() => {
                            setIsOpenConfirmDeletionModal(true);
                            setFileToDelete(equipmentFile.file);
                          }}
                        >
                          <IoMdTrash style={{ width: '1.2rem', height: '1.2rem' }} />
                          <span style={{ fontSize: '0.8rem' }}>Deletar</span>
                        </div>
                      </div>
                    </ListItem>
                  ))}
                </List>
              ) : (
                <p>Nenhum arquivo disponível.</p>
              )}
            </ScrollMenu>
          </ModalBody>
        </ModalContent>
        <ConfirmDeleteFileModal 
          moduleUuid={moduleUuid} 
          isOpen={isOpenConfirmDeletionModal} 
          setIsOpenConfirmDeletionModal={setIsOpenConfirmDeletionModal}
          file={fileToDelete} 
          setLoadList={setLoadList} 
          loadList={loadList}
          deleteFile={deleteFile}
        />
      </Modal>
    </ModalOverlay>
  ) : null;
};

FilesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  moduleUuid: PropTypes.string.isRequired,
};

export default FilesModal;
