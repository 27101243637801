const styled = require('styled-components').default;

export const Modal = styled.div`
  display: ${props => (props.isOpen ? 'flex' : 'none')};
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  justify-content: center;
  align-items: center;
`;
export const ScrollMenu = styled.div`
  overflow-x: auto;
  white-space: nowrap;
`;
export const ModalContent = styled.div`
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 900px;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Close = styled.span`
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;

  &:hover,
  &:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
`;

export const ModalBody = styled.div`
  padding: 2px 16px;
`;

export const ModalFooter = styled.div`
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
`;

export const DeleteButton = styled.button`
  height: 40px;

  min-width: 132px;

  border-radius: 4px;

  padding: 0 15px;

  box-sizing: border-box;

  background: #59d99d;

  font-size: 16px;

  font-weight: bold;

  color: #fff;

  margin-top: 32px;

  &:hover {
    background: rgba($color: #59d99d, $alpha: 0.2);
  }

  &.delete {
    background: #bf1523;

    background: rgba($color: '#bf1523', $alpha: 0.2);
  }

  &:disabled {
    background: #ddd;

    color: #999;

    cursor: default;
  }
`;
