import React from 'react';
import PropTypes from 'prop-types';
import { BiFile } from 'react-icons/bi';
import { StyledButton } from './style';

const FileButton = ({ fileInputRef, label, attachNewFile }) => {

  return (
    <>
      <StyledButton
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '8px',
          cursor: 'pointer',
        }}
        onClick={() => fileInputRef.current.click()}
      >
        <BiFile size={24} />
        <span style={{ marginLeft: '8px', fontSize: '1rem' }}>
          {label || 'ANEXAR ARQUIVO'}
        </span>
      </StyledButton>
      <input
        type="file"
        ref={fileInputRef}
        onChange={attachNewFile}
        style={{ display: 'none' }}
      />
    </>
  );
};

FileButton.propTypes = {
  onFileSelect: PropTypes.func.isRequired,
  label: PropTypes.string,
};

export default FileButton;

