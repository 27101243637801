const styled = require('styled-components').default;

export const StyledButton = styled.div`
  height: 40px;

  min-width: 132px;

  border-radius: 4px;

  padding: 0 15px;

  box-sizing: border-box;

  background: #59d99d;

  font-size: 16px;

  font-weight: bold;

  color: #fff;

  margin-top: 32px;

  &:hover {
    background: rgba($color: #59d99d, $alpha: 0.2);
  }

  &.delete {
    background: #bf1523;

    background: rgba($color: '#bf1523', $alpha: 0.2);
  }

  &:disabled {
    background: #ddd;

    color: #999;

    cursor: default;
  }
`;

