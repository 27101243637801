import { keyframes } from 'styled-components';

const styled = require('styled-components').default;

export const Modal = styled.div`
  display: ${props => (props.isOpen ? 'flex' : 'none')};
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  justify-content: center;
  align-items: center;
`;
export const ScrollMenu = styled.div`
  overflow-x: auto;
  white-space: nowrap;
`;
export const ModalContent = styled.div`
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 900px;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Close = styled.span`
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;

  &:hover,
  &:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
`;

export const ModalBody = styled.div`
  padding: 2px 16px;
`;

export const ModalFooter = styled.div`
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
`;

export const SlideInLeft = keyframes`
from {
    opacity: 0;
    transform: translateX(-3%);
  }
  80% {
    opacity: 0.5;
  }
  95% {
    opacity: 0.8;
    transform: translateX(0);
  }
  to {
    opacity: 1;
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 500px;
  overflow-y: auto;
  gap: 10px;
  animation: ${SlideInLeft} 0.5s forwards;
`;

export const Container = styled.div`
  padding: 20px;
  height: 100%;
`;

export const WrapperListFiles = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const Title = styled.h2`
  font-size: 24px;
  color: #333;
`;

export const TabContainer = styled.div`
  display: flex;
  gap: 2px;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const GripDiv = styled.div`
  gap: 1rem;
  display: flex;
`;

export const ListContainer = styled.div`
  background: white;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`;

export const ListHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 15px;
  background: #f8f9fa;
  border-bottom: 1px solid #dee2e6;
  font-weight: 600;
`;

export const ListItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border: 1px solid #dee2e6;
  height: 70px;
  padding-left: 15px;
`;

export const PdfViewr = styled.div`
  height: 750px;
  width: 100%;
`;

export const Buttons = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  background: #00a65a;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.2s;

  &:hover {
    background: #008d4c;
  }

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const AnimateLeft = styled.div`
  animation: ${SlideInLeft} 0.5s ease-in-out;
`;

export const StyledReactIcon = (Icon) => styled(Icon)`
  color: white;
  font-size: 24px;
  transition: color 0.2s;
  width: 1.2rem;
  height: 1.2rem;

  &:hover {
    color: #ccc;
  }
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;